import React, {useState,useEffect} from 'react'
import {useTheme} from '../components/Theming'
//import Container from '../components/Container'
import {graphql,Link} from 'gatsby'
import Layout from '../components/Layout'
//import Hero from '../components/Hero'
import Lightbox from 'react-spring-lightbox'
import {css} from '@emotion/core'
//import {Dialog} from '@reach/dialog'
import {FaArrowRight,FaArrowLeft, FaWindowClose} from 'react-icons/fa'
import Img from 'gatsby-image/withIEPolyfill'
import titel from './may'
import './styles.css'

const BenjaminMay = ({data}) => {
    const theme = useTheme()
  
    const [currentImageIndex, setCurrentIndex] = useState(0);
    const [images,setImages]=useState(null)
    const [light,setLight]=useState(false)
    console.log(data)
    
      function los(daten){
        let mdat=[]
         daten.map((item,index) => {
         
          let a=item.src
          let b=a.lastIndexOf('/')
          let c = a.slice(b+1,a.length)
          let d=c.split('.')
        let arr= parseInt(d[0])
                return mdat.push({src:item.src,id:arr,titel:titel.benjamindaten[arr-1].titel}) 
         })
        mdat.sort((a,b) => (parseInt(a.id) > parseInt(b.id)) ? 1: -1)
       
        return mdat
        
      }
  
    const gotoPrevious = () =>
        currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);
  
    const gotoNext = () =>
        currentImageIndex + 1 < images.length &&
        setCurrentIndex(currentImageIndex + 1);
  
     useEffect(()=>{
         function makeImg(){
          const myImages= data.benjamin.nodes.map((item,index)=> {
       
            return item.childImageSharp.fluid
           })
          
           let daten = los(myImages)
  
          
           return setImages(daten)
          
        }
        makeImg()
        
     },[data.benjamin.nodes])   
  
     function handleClose(){
         setLight(!light)
     }
     function handleClose2(index,item){
     
      setCurrentIndex(index)
      setLight(!light)
   
      
  }
     function mach(){
       
         currentImageIndex<images.length-1 &&
         setCurrentIndex(currentImageIndex+1)
     }
     function lmach(){
       
        currentImageIndex>=1 &&
        setCurrentIndex(currentImageIndex-1)
    }
   
    const CustomFooter =()=> {
      
        return <span style={{padding:4,left:'2%',zIndex:55000,color:'white',textAlign:'center',}}>
             {images[currentImageIndex].titel}  <br/>{images[currentImageIndex].id} / {images.length} </span>
    }
  
     const CustomLeftArrowButton =()=> {
       
     return <button style={{border:"blue",background:'none',position:'fixed',top:'50%',right:'1%',zIndex:2000}} onClick={()=>mach()}><span style={{color:'#00ffcc',cursor:'pointer',fontSize:'1.2rem'}}>{currentImageIndex<images.length-1?<FaArrowRight />:null}</span></button>
    }
    const CustomRightArrowButton =()=> {
      
        return <button style={{border:"blue",background:'none',position:'fixed',top:'50%',left:'1%',zIndex:2000}} onClick={()=>lmach()}><span style={{color:'#00ffcc',cursor:'pointer',fontSize:'1.2rem'}}>{currentImageIndex>0?<FaArrowLeft />:null}</span></button>
    }
   
    const CustomCloseButton =()=> {
        return <button aria-label="close Diashow" style={{border:"blue",background:'none',position:'fixed',top:'60%',right:'1%',zIndex:20000}} onClick={()=>handleClose()}><span style={{color:'#00ffcc',fontSize:'1.2rem',cursor:'pointer'}}><FaWindowClose /></span></button>
    }
    return (
        <Layout site={data.site}>
           <div
          css={css`
            display:flex;
            flex-direction:column;
            width:100%;
            margin:0px auto 0px auto;
            padding:0;
            max-width:1200px;
           
  
          `}>
         <h1 css={css`
           font-size:${theme.fontsizes.great};
           font-weight: 500;
           text-align:center;
           padding-top:30px;
           padding-bottom:25px;
           margin:0;
           @media(max-width:800px){
             font-size:${theme.fontsizes.middle};
           }
          //  img {
          //    object-fit:cover;
          //    max-height:400px;
          //  }
         `
          }><Link css={css`
          font-size:${theme.fontsizes.great}
          `} to="/lepidoptera">Lepidoptera</Link></h1>
         <h2
            css={css`
               text-align:center;
               padding:0;
               margin:0 0 30px 0;
               font-size:${theme.fontsizes.h2great};
               @media(max-width:800px)
                font-size:${theme.fontsizes.h2middle};
              }
            `}
         
          > Fotografien von Benjamin May </h2>
         
  
  
  
         {typeof window &&
         <button css={css`
          background-color:transparent;
          margin-bottom:20px;
         `}
         onClick={()=>{
          setCurrentIndex(0)
          handleClose()
          return 
        }} 
        onKeyPress={()=>{
          setCurrentIndex(0)
          handleClose()
          return 
        }} 
         >
   <div css={css`
    max-height: 700px;
    display:flex;
    justify-content:center;
    width:100%;
    object-fit:contain;
    overflow:hidden;
    object-position-y:10%;
   
  `}>
  <Img 
         css={css`
         height:700px;
         width:1200px;
         @media(min-width:800px){
          max-width:90%;
          height:auto;
         
       }
           
         `} fluid={data.benjamin.nodes[0].childImageSharp.fluid} alt="Bildergalerie" />
  </div>
  </button>
  
  }
    
  
  </div>
  
  {
    images ?
    <div css={css`
      display:flex;
      flex-wrap:wrap;
      justify-content:center;
     
    `}>
    { images.map((item,index)=> {
      return <div key={index} css={css`
       
      width:120px;
      height:120px;
      padding:0;
      margin:1px;
      background-color: transparent;
      overflow:hidden;
      img {
        object-fit:cover;
        padding:0;
        margin:1px;
        width:10vw;
        min-width:120px;
        height:calc(15vh-5px);
        min-height:120px;
        cursor:pointer;
        background-color: transparent;
      }
    `}
    > 
      <button 
         onKeyPress={()=>handleClose2(index,item)}
         onClick={()=>handleClose2(index,item)}
      >
        <img key={index} 
      src={item.src} alt="Lepidoptera" />
      </button>
      </div>
    })}
  </div>
  :<div className="mybounce">
  <span>...lade Fotos</span>
  </div>
  }
         
         { light?
             <Lightbox
             isOpen={true}
             onPrev={gotoPrevious}
             onNext={gotoNext}
             images={images}
             currentIndex={currentImageIndex}
             style={{background:'black'}}
             /* Add your own UI */
             //renderHeader={() => <CustomHeader />}
             renderFooter={() => <CustomFooter />}
             renderPrevButton={() => <CustomLeftArrowButton />}
             renderNextButton={() => <CustomRightArrowButton />}
             renderCloseButton={() => <CustomCloseButton />}
             // renderImageOverlay={() => (<ImageOverlayComponent >)}
            
             /* Add styling */
             // className="cool-class"
             // style={{ background: "grey" }}
   
             /* Handle closing */
             
   
             /* Use single or double click to zoom */
             // singleClickToZoom
   
             /* react-spring config for open/close animation */
             // pageTransitionConfig={{
             //   from: { transform: "scale(0.75)", opacity: 0 },
             //   enter: { transform: "scale(1)", opacity: 1 },
             //   leave: { transform: "scale(0.75)", opacity: 0 },
             //   config: { mass: 1, tension: 320, friction: 32 }
             // }}
         />
         
  
             :null
  
         }
         {
            light && <div>
              <CustomCloseButton />
         
          <CustomFooter/>
          
          </div>
            }
         
        </Layout>
    );
  };
  
  export default BenjaminMay  
  
  export const leeQuery = graphql`
      query {
          site {
            ...site
            siteMetadata {
              title
            }
          }
          benjamin: allFile (sort:{fields:relativePath},filter: {sourceInstanceName: {eq: "may"}}){
            nodes {
             relativePath
             childImageSharp {
              fluid (maxWidth: 1200) {
               ...GatsbyImageSharpFluid
              }
             } 
            
             
           }
           }
      }
      `
  